window.laprise = (window.laprise || {})

laprise.Contact = (function Contact($) {

    'use strict';
    /**
     * jQuery elements.
     * @private
     */
    var $form = $('.js-form');
    var $ctaBtn = $('.js-cta');
    var $close = $('.js-close');
    var $succes = $('.js-succes');
    var $file = $('.js-file');
    var $fileLabel = $('.js-fileLabel');

    /**
     * Has the application been initialized?
     * @private
     */
    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        $ctaBtn.on('click',function(e){

            openForm();
            e.preventDefault();
        });

        $close.on('click', function(e){

            if(e.target==this){
                closeForm();
                e.preventDefault();
            }
        });

        $form.parsley().on('form:submit', function (e) {

            submitForm();
            return false;
         });

        $file.change(function(e){

           getFileName();
        });

        // return success
        return true;

    };

    var openForm = function(){
            $('body').addClass('noScroll');
            $close.addClass('show-form');
    };

    var closeForm = function(){
        $('body').removeClass('noScroll');
        $close.removeClass('show-form');
    };

    var getFileName = function(){

        var $filename = $('input[type=file]').val().replace(/C:\\fakepath\\/i, '');
        $fileLabel.text($filename);

    };

    var submitForm = function(){

        var data = new FormData($form[0]);

        $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: data,
                contentType: false,
                processData: false,
                success: function (data) {

                    $form.fadeOut(300);
                    $succes.fadeIn(300);
                    console.log(data);
                },
                error: function (response) {
                    console.log(data);
                    alert('Une erreur est survenue lors de l\'envoie de votre message');
                },
            });
    };

    // Expose public methods & properties
    return {
        init: init,
    };

})(jQuery);
