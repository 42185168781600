window.laprise = (window.laprise || {});

/**
 * Utilities for interacting with the application.
 * @class App
 * @static
 */
laprise.App = (function App($) {

    'use strict';

    /**
     * Has the application been initialized?
     * @private
     */
    var inited = false;

    /**
     * Application config defaults.
     * @private
     * @param {Object} config.env     Current server environment
     * @param {Object} config.locale  Current locale short code
     */
    var config = {
        env: 'local',
        locale: 'fr',
    };

    /**
     * Initializes the class.
     * @public
     */
    var init = function (options) {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        // Store application settings
        options = (options || {});

        if (options.env) { config.env = options.env; }
        if (options.locale) { config.locale = options.locale; }


        if (typeof laprise.Burger === 'object') {
            laprise.Burger.init();
        }

        if (typeof laprise.Contact === 'object') {
            laprise.Contact.init();
        }

        // Return success
        return true;

    };

    /**
     * Getter for application config.
     * @public
     */
    var getConfig = function (option) {
        return config[option] ? config[option] : false;
    };

    /**
     * Expose public methods & properties.
     */
    return {
        init: init,
        getConfig: getConfig,
    };

})(jQuery);
