window.laprise = (window.laprise || {})

laprise.Burger = (function Burger($) {

    'use strict';
    /**
     * jQuery elements.
     * @private
     */
    var $burgerBtn = $('.js-burger-btn');
    var $burgerMenu = $('.js-burger-menu');

    /**
     * Has the application been initialized?
     * @private
     */
    var inited = false;

    /**
     * Initializes the class.
     * @public
     */
    var init = function () {

        // Abort if already initialized
        if (inited) {
            return false;
        }

        inited = true;

        $burgerBtn.on('click', function (e) {
            var $this = $(this);
            toggleMenu($this);
        });

        // return success
        return true;

    };

    var toggleMenu = function (e) {
        if (e.hasClass('is-active')) {
            e.removeClass('is-active');
            $burgerMenu.slideUp('slow');
        }
        else {
            e.addClass('is-active');
            $burgerMenu.slideDown('slow');
        }
    };



    // Expose public methods & properties
    return {
        init: init,
    };

})(jQuery);
